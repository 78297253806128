import React, { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

// const safeLocalStorageGetItem = (key) => {
//     if (typeof localStorage !== 'undefined' && localStorage !== null) {
//         return localStorage.getItem(key);
//     }
//     return '';
// };

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || '');
    const [customerType, setCustomerType] = useState(localStorage.getItem('customer_type') || '');
    const [customerPhoneNo, setCustomerPhoneNo] = useState(localStorage.getItem('loginUserPhoneNo') || '');
    const [checkIsLoggedIn, setCheckIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') || '');
    const [loginUserName, setLoginUserName] = useState(localStorage.getItem('loginUserName') || '');
    const [singleItemQuantity, setSingleItemQuantity] = useState(1);
    const [pageDetail, setPageDetail] = useState('');
    const [userTokenValidate, setCheckUserTokenValidate] = useState(true);
    const [backEndLogin, setIsBackEndLogin] = useState(localStorage.getItem('isBackEndLogin') || '');
    // const [cartCount, setcartCount] = useState('');
    const [salesPriceSystemId, setSalesPriceSystemId] = useState('');
    const [appLogo, setAppLogo] = useState('');
    const [whatsappLink, setWhatsappLink] = useState('');
    const [companyPhoneNo, setCompanyPhoneNo] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [userRoleType, setUserRoleType] = useState(localStorage.getItem('role_type') || '');
    const [cartCount, setCartCount] = useState(0);
    const [getWishListItems, setWishListDetails] = useState('');
    const [masterPassword, setMasterPassword] = useState(0);
    const [cartDetails, setCartDetails] = useState(0);

    const navigate = useNavigate();

    // Auth Hooks
    const updateToken = (newToken) => {
        setToken(newToken);
    };

    // Get Customer Type
    const getCustomerType = useCallback((customer_type) => {
        setCustomerType(customer_type);
    }, []);

    // Get Customer Type
    const getCustomerPhoneNo = useCallback((loginUserPhoneNo) => {
        setCustomerPhoneNo(loginUserPhoneNo);
    }, []);

    const updateUserTokenValidate = (tokenValidate) => {
        setCheckUserTokenValidate(tokenValidate);
    };

    const isLoggedIn = (loggedIn) => {
        setCheckIsLoggedIn(loggedIn);
    };

    const updateLoginUserName = (userName) => {
        setLoginUserName(userName);
    };

    const isBackEndLogin = (isBackEndLogin) => {
        setIsBackEndLogin(isBackEndLogin);
    }

    // Common Hook
    const itemQuantity = useCallback((quantity) => {
        setSingleItemQuantity(quantity);
    }, []);

    const pageDetails = useCallback((page_detail) => {
        setPageDetail(page_detail);
    }, []);

    const updateSalesPriceSystemId = useCallback((sales_price_system_id) => {
        setSalesPriceSystemId(sales_price_system_id);
    }, []);

    const updateAppLogo = useCallback((logo) => {
        setAppLogo(logo);
    }, []);

    const updateWhatsappLink = useCallback((whatsapp_link) => {
        setWhatsappLink(whatsapp_link);
    }, []);

    const updateCompanyPhoneNo = useCallback((phone_number) => {
        setCompanyPhoneNo(phone_number);
    }, []);

    const updateCompanyEmail = useCallback((company_email) => {
        setCompanyEmail(company_email);
    }, []);

    const updateUserRoleType = useCallback((role_type) => {
        setUserRoleType(role_type);
    }, []);

    // Cart
    const updateCartCount = useCallback((count) => {
        setCartCount(count);
    }, []);
    const updateCartDetails = useCallback((cart_details) => {
        setCartDetails(cart_details);
    }, []);

    // WishList
    const updateWishListDetails = useCallback((count) => {
        setWishListDetails(count);
    }, []);

    const updateMasterPassword = useCallback((master_password) => {
        setMasterPassword(master_password);
    }, []);

    // useEffect(() => {
    //     const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    //     if (/android/i.test(userAgent)) {
    //     setDeviceType('mobile');
    //     }  else {
    //     setDeviceType('desktop');
    //     }
    // },[]);
    
    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(min-width: 320px) and (max-width: 767px)").matches) {
                setDeviceType('mobile');
            } else {
                setDeviceType('desktop');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // },[]);
    
    // To Remove Extra '/' From URL
    useEffect(() => {
        const removeExtraSlash  = () => {
            const { pathname } = window.location;
      
            // Normalize the path by replacing multiple slashes with a single one
            const normalizedPath = pathname.replace(/\/{2,}/g, '/');
      
            // If the path was modified, redirect to the normalized URL
            if (pathname !== normalizedPath) {
              navigate(`${normalizedPath}`, { replace: true });
            }
          };
      
          removeExtraSlash();
      
          window.addEventListener('popstate', removeExtraSlash);
      
          return () => {
            window.removeEventListener('popstate', removeExtraSlash);
          };
    }, []);

    return (
        <AuthContext.Provider value={{
            token, updateToken,
            checkIsLoggedIn, isLoggedIn,
            loginUserName, updateLoginUserName,
            singleItemQuantity, itemQuantity,
            pageDetail, pageDetails,
            userTokenValidate, updateUserTokenValidate,
            backEndLogin, isBackEndLogin,
            // cartCount, updateCartCount,
            salesPriceSystemId, updateSalesPriceSystemId,
            appLogo, updateAppLogo,
            whatsappLink, updateWhatsappLink,
            companyPhoneNo, updateCompanyPhoneNo,
            companyEmail, updateCompanyEmail,
            deviceType,
            userRoleType, updateUserRoleType,
            customerType, getCustomerType,
            customerPhoneNo, getCustomerPhoneNo,
            cartCount, updateCartCount,
            cartDetails, updateCartDetails,
            getWishListItems, updateWishListDetails,
            masterPassword, updateMasterPassword,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
