import axios from "axios";
import { toast } from "react-toastify";
import { checkUserToken } from "./CommanAPI";
import { clearConsole } from "../helpers/clearConsole";
// import { decrypt, isBase64 } from "../helpers/JWTToken";

// Get Method Api
export const apiget = async (path, data) => {
    try {
        const nullableData = data ?? {};
        // if (process.env.REACT_APP_CONSOLE === 'production') {
        //     if (isBase64(localStorage.getItem('token')) === false) {
        //         console.log('isBase64 false 1', isBase64(localStorage.getItem('token')))
        //         localStorage.setItem('token', btoa(localStorage.getItem('token')));
        //     }
        //     var token = atob(localStorage.getItem('token'));
        // } else {
        //     if (isBase64(localStorage.getItem('token')) === true) {
        //         console.log('isBase64 true 1', isBase64(localStorage.getItem('token')))
        //         localStorage.setItem('token', atob(localStorage.getItem('token')));
        //     }
        //     var token = localStorage.getItem('token');
        // }
        let response = await axios.get(process.env.REACT_APP_API_URL + path, {
            nullableData,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'content-type': 'application/json',
                'device': 'Website'
            },
            credentials: 'include',
        });
        
        // Clear Console If API Call Successfully
        if (process.env.REACT_APP_CONSOLE === 'production') {
            if (response.data && response.data.data && response.data.status === true) {
                clearConsole();
            }
        }

        // Logout If Token Is Expired
        if (response.data.status === false && response.data.type === 'check_token') {
            checkUserToken();
        }
        
        // Store token
        if (response.data && response.data.data && response.data.data.token && response.data.data.token !== null) {
            localStorage.setItem('token', response.data.data.token);
        }
    
        return response;
    } catch(error) {
        if (error?.response?.data?.status === false) {
            // Logout If Token Expire
            if (error?.response?.data?.error_type === 'check_token') {
                checkUserToken();
            }
            if (error?.response?.data?.message) {
                if (error?.response?.data?.error_type !== 'check_token') {
                    toast.error(error.response.data.message);
                }
                if (error?.response?.data?.error_type === 'check_token') {
                    return error.response.data;
                }
            }
        }
    }
}

// Post Method Api
export const apipost = async (path, data) => {
    try {
        const nullableData = data ?? {};
        // if (process.env.REACT_APP_CONSOLE === 'production') {
        //     if (isBase64(localStorage.getItem('token')) === false) {
        //         console.log('isBase64 false 2', isBase64(localStorage.getItem('token')))
        //         localStorage.setItem('token', btoa(localStorage.getItem('token')));
        //     }
        //     var token = atob(localStorage.getItem('token'));
        // } else {
        //     if (isBase64(localStorage.getItem('token')) === true) {
        //         console.log('isBase64 token 2', isBase64(localStorage.getItem('token')))
        //         localStorage.setItem('token', atob(localStorage.getItem('token')));
        //     }
        //     var token = localStorage.getItem('token');
        // }
        const response = await axios.post(process.env.REACT_APP_API_URL + path, nullableData, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'content-type': 'application/json',
                'device': 'Website'
            },
            credentials: 'include',
        });

        // Logout If Token Is Expired
        if (response.data.status === false && response.data.type === 'check_token') {
            checkUserToken();
        }

        // Clear Console If API Call Successfully
        if (process.env.REACT_APP_CONSOLE === 'production') {
            if (response.data && response.data.data && response.data.status === true) {
                clearConsole();
            }
        }

        return response;
    } catch(error) {
        if (error?.response?.data?.status === false) {
            // Logout If Token Expire
            if (error?.response?.data?.error_type === 'check_token') {
                checkUserToken();
            }
            if (error?.response?.data?.status === false) {
                return error.response.data;
            }
            if (error?.response?.data?.errors) {
                return error.response.data;
            }
        }
    }
}
