import React, { useCallback, useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from './service/AuthContext';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { apiget } from './service/API.js';
import Loader from './view/Component/Layout/Loader.js';
import Header from './view/Component/Layout/Header';

// Layout
// const Header = lazy(() => import('./view/Component/Layout/Header'));
const CustomerHeader = lazy(() => import('./view/Component/Layout/CustomerHeader.js'));
const Footer = lazy(() => import('./view/Component/Layout/Footer'));
const ScrollToTop = lazy(() => import('./view/Component/Layout/ScrollToTop.js'));
const Whatsapp = lazy(() => import('./view/Component/Layout/Whatsapp.js'));

//Download APK
const DownloadPromotSwitchesAPK = lazy(() => import('./view/Component/DownloadAPK/DownloadPromotSwitchesAPK'));
const DownloadDevPromotSwitchesAPK = lazy(() => import('./view/Component/DownloadAPK/DownloadDevPromotSwitchesAPK'));

// Home
const Home = lazy(() => import('./view/Component/Home/Home'));
const HomeWithLogin = lazy(() => import('./view/Component/Home/HomeWithLogin'));
// Shop By Category
const ShopByCategory = lazy(() => import('./view/Component/ShopByCategory/ShopByCategory'));
// Shop By Tag
const ShopByTag = lazy(() => import('./view/Component/ShopByTag/ShopByTag'));
// Item Details
const ItemDetails = lazy(() => import('./view/Component/Item/ItemDetails'));
// My WishList
const MyWishList = lazy(() => import('./view/Component/WishList/MyWishList'));
// Cart
const Cart = lazy(() => import('./view/Component/Cart/Cart'));
// Address
const Address = lazy(() => import('./view/Component/Address/Address'));
// Profile Setting
const ProfileSetting = lazy(() => import('./view/Component/ProfileSetting/ProfileSetting.js'));
// Check Out
const CheckOut = lazy(() => import("./view/Component/CheckOut/CheckOut"));
// My Order
const MyOrder = lazy(() => import("./view/Component/Order/MyOrder"));
const MyOrderDetail = lazy(() => import("./view/Component/Order/MyOrderDetail"));
const MyOrderItemWise = lazy(() => import("./view/Component/Order/MyOrderItemWise"));
const MyItemWiseOrderDetail = lazy(() => import('./view/Component/Order/MyItemWiseOrderDetail.js'));
// Customer Sales Invoice
const SalesInvoice = lazy(() => import('./view/Component/SalesInvoice/SalesInvoice'));
const CustomerSalesInvoiceDetail = lazy(() => import('./view/Component/SalesInvoice/SalesInvoiceDetail'));
// Customer Credit Note
const CreditNote = lazy(() => import('./view/Component/CreditNote/CreditNote'));
// Customer Payment 
const SalesPayment = lazy(() => import('./view/Component/SalesPayment/SalesPayment'));
const SalesPaymentDetail = lazy(() => import('./view/Component/SalesPayment/SalesPaymentDetail'));
// Retailer
const ListRetailer = lazy(() => import('./view/Component/Retailer/ListRetailer.js'));
const AddRetailer = lazy(() => import('./view/Component/Retailer/AddRetailer.js'));
const EditRetailer = lazy(() => import('./view/Component/Retailer/EditRetailer.js'));
// Electrician
const ListElectrician = lazy(() => import('./view/Component/Electrician/ListElectrician.js'));
const AddElectrician = lazy(() => import('./view/Component/Electrician/AddElectrician.js'));
const EditElectrician = lazy(() => import('./view/Component/Electrician/EditElectrician.js'));
// Page
const Page = lazy(() => import('./view/Component/Page/Page'));
const CorporateAnnouncements = lazy(() => import('./view/Component/Page/corporate-announcements'));
const FAQ = lazy(() => import('./view/Component/Page/FAQ'));
// Request New Product
const RequestNewProduct = lazy(() => import('./view/Component/RequestNewProduct/RequestNewProduct.js'));
// Support Request
const AddSupportRequest = lazy(() => import('./view/Component/SupportRequest/AddSupportRequest.js'));
const ListSupportRequest = lazy(() => import('./view/Component/SupportRequest/ListSupportRequest.js'));
// Contact Us
const ContactUs = lazy(() => import('./view/Component/ContactUs/ContactUs.js'));
// Search Result
const SearchResult = lazy(() => import('./view/Component/Page/SearchResult.js'));
// Ammount To Pay
const AmmountToPay = lazy(() => import('./view/Component/SalesInvoice/AmmountToPay.js'));
// Page Not Found
const PageNotFound = lazy(() => import('./view/Component/Page/PageNotFound.js'));
// Customer List
const CustomerList = lazy(() => import('./view/Component/CustomerList/CustomerList.js'));

function App() {
    // Custom Hook
    const AuthHook = useAuth();

    const [getCartDetails, setGetCartDetails] = useState('');
    const [getWishListItems, setGetWishListItems] = useState('');
    const [notifications, setNotification] = useState('');
    const hasLoggedInFromBackEnd = useRef(false);

    // Get Notification API
    const getNotifications = useCallback(async () => {
        const getNotificationsResult = await apiget('order/get-notification');
        if (getNotificationsResult?.data?.status === true) {
            setNotification(getNotificationsResult.data.data);
        } else {
            toast.error(getNotificationsResult.data.message);
        }
    }, []);

    const activeElementRef = useRef(null);

    // Login From Backend
    useEffect(() => {
        const handleLogin = async () => {
            try {
                await AuthHook.isBackEndLogin('fa');
                if (AuthHook.backEndLogin !== 'true') {
                    const params = new URLSearchParams(window.location.search);
                    const user_token = params.get('token');
                    const user_name = params.get('name');
                    const userPhoneNo = params.get('phone_no');
                    const user_role_type = params.get('role_type');

                    if (user_token) {
                        window.location.reload();
                        localStorage.setItem('token', user_token);
                        localStorage.setItem('isLoggedIn', 'true');
                        localStorage.setItem('isBackEndLogin', 'true');
                        localStorage.setItem('role_type', 'Backend');
                        localStorage.setItem('customer_type', user_role_type);
                        AuthHook.getCustomerType(user_role_type);
                        AuthHook.updateToken(user_token);
                        AuthHook.isLoggedIn('true');
                        AuthHook.isBackEndLogin('true');
                    }
                    if (user_name) {
                        localStorage.setItem('loginUserName', user_name);
                        AuthHook.updateLoginUserName(user_name);
                    }
                    if (userPhoneNo) {
                        localStorage.setItem('loginUserPhoneNo', userPhoneNo);
                    }
                    
                    if (!hasLoggedInFromBackEnd.current && AuthHook.token && AuthHook.userRoleType !== 'Frontend') {
                        getNotifications();
                        hasLoggedInFromBackEnd.current = true;
                    }
                    window.history.replaceState(null, '', window.location.pathname);
                }
            } catch (error) {
                console.error(`Error while login fro backend: ${error}`);
            }
        };
        if (!activeElementRef.current) {
            activeElementRef.current = true;
            handleLogin();
        }
    }, [AuthHook.token, AuthHook.updateToken, AuthHook.backEndLogin, AuthHook.isBackEndLogin, AuthHook.isLoggedIn, AuthHook.updateLoginUserName, getNotifications]);

    // Get Wishlist
    const getWishList = useCallback(async () => {
        const result = await apiget('wishlist/get-wishlist');
        // API Error While Get Wishlist
        if (result?.data?.status === false) {
            toast.error(result.data.message);
        }
        // Get Wishlist Successfully
        if (result?.data?.status === true) {
            AuthHook.updateWishListDetails(result.data.data);
            setGetWishListItems(result.data.data);
        }
    }, [AuthHook.updateWishListDetails]);

    // Get Cart
    const getCart = useCallback(async () => {
        const result = await apiget('cart/get-cart');
        // API Error While Get Cart
        if (result?.data?.status === false) {
            toast.error(result.data.message);
        }
        // Get Cart Successfully
        if (result?.data?.status === true) {
            setGetCartDetails(result.data.data);
            AuthHook.updateCartCount(result.data.data.total_items || 0);
        }
    }, [AuthHook.updateCartCount]);

    return (
        <React.Fragment>
            <Header getWishListItems={getWishListItems} getNotifications={getNotifications} notifications={notifications}/>
            <Suspense fallback={<Loader />}>
                <ToastContainer />
                {AuthHook.userRoleType !== 'Frontend' ? (
                    <React.Fragment>
                        <Routes>
                            {/* Home */}
                            <Route exact path="/" element={AuthHook.checkIsLoggedIn === 'true' ? <HomeWithLogin getNotifications={getNotifications} /> : <Home />} />

                            {/* Shop By Category */}
                            <Route exact path="/shop-by-category/:category_id?/:sub_category_id?" element={<ShopByCategory getNotifications={getNotifications}/>} />

                            {/* Shop By Tag */}
                            <Route exact path="/shop-by-tag/:tag_id" element={<ShopByTag getNotifications={getNotifications}/>} />

                            {/* Item Details */}
                            <Route exact path="/item-details/:item_system_id" element={<ItemDetails />} />

                            {/* My WishList */}
                            <Route exact path="/my-wishlist" element={AuthHook.checkIsLoggedIn === 'true' ? <MyWishList /> : <Navigate to="/" replace />} />

                            {/* Cart */}
                            <Route exact path="/my-cart" element={AuthHook.checkIsLoggedIn === 'true' ? <Cart /> : <Navigate to="/" replace />} />

                            {/* Address */}
                            <Route exact path="/address" element={AuthHook.checkIsLoggedIn === 'true' ? <Address /> : <Navigate to="/" replace />} />

                            {/* Profile Setting */}
                            <Route exact path="/profile-setting" element={<ProfileSetting />} />

                            {/* Check Out */}
                            <Route exact path="/check-out" element={AuthHook.checkIsLoggedIn === 'true' ? <CheckOut getNotifications={getNotifications} /> : <Navigate to="/" replace />} />

                            {/* My Order */}
                            <Route exact path="/order/my-order" element={AuthHook.checkIsLoggedIn === 'true' ? <MyOrder /> : <Navigate to="/" replace />} />
                            <Route exact path="/order/my-order-detail/:order_id" element={AuthHook.checkIsLoggedIn === 'true' ? <MyOrderDetail getNotifications={getNotifications} />  : <Navigate to="/" replace />} />
                            <Route exact path="/order/my-order-item-wise" element={AuthHook.checkIsLoggedIn === 'true' ? <MyOrderItemWise /> : <Navigate to="/" replace />} />
                            <Route exact path="/order/my-order-detail-item-wise/:order_detail_id" element={<MyItemWiseOrderDetail />} />

                            {/* Customer Sales Invoice */}
                            <Route exact path="/sales-invoice" element={AuthHook.checkIsLoggedIn === 'true' ? <SalesInvoice /> : <Navigate to="/" replace />} />
                            <Route exact path="/sales-invoice-detail/:sales_invoice_header_system_id" element={AuthHook.checkIsLoggedIn === 'true' ? <CustomerSalesInvoiceDetail /> : <Navigate to="/" replace />} />

                            {/* Customer Credit Note */}
                            <Route exact path="/credit-note" element={AuthHook.checkIsLoggedIn === 'true' ? <CreditNote /> : <Navigate to="/" replace />} />

                            {/* Customer Payment */}
                            <Route exact path="/sales-payment" element={AuthHook.checkIsLoggedIn === 'true' ? <SalesPayment /> : <Navigate to="/" replace />} />
                            <Route exact path="/sales-payment-detail/:SystemId_Ledger" element={AuthHook.checkIsLoggedIn === 'true' ? <SalesPaymentDetail /> : <Navigate to="/" replace />} />

                            {/* Add Retailer */}
                            <Route exact path="/list-retailer" element={AuthHook.checkIsLoggedIn === 'true' ? <ListRetailer /> : <Navigate to="/" replace />} />
                            <Route exact path="/add-retailer" element={AuthHook.checkIsLoggedIn === 'true' ? <AddRetailer /> : <Navigate to="/" replace />} />
                            <Route exact path="/edit-retailer/:SystemId" element={AuthHook.checkIsLoggedIn === 'true' ? <EditRetailer /> : <Navigate to="/" replace />} />

                            {/* Add Electrician */}
                            <Route exact path="/list-electrician" element={AuthHook.checkIsLoggedIn === 'true' ? <ListElectrician /> : <Navigate to="/" replace />} />
                            <Route exact path="/add-electrician" element={AuthHook.checkIsLoggedIn === 'true' ? <AddElectrician /> : <Navigate to="/" replace />} />
                            <Route exact path="/edit-electrician/:SystemId" element={AuthHook.checkIsLoggedIn === 'true' ? <EditElectrician /> : <Navigate to="/" replace />} />

                            {/* Page */}
                            <Route exact path="/page/:page_id" element={<Page />} />
                            <Route exact path="/page/corporate-announcements" element={<CorporateAnnouncements />} />
                            <Route exact path="/page/FAQ" element={<FAQ />} />

                            {/* Request new product */}
                            <Route exact path="/request-product" element={<RequestNewProduct />} />

                            {/* Add Retailer */}
                            <Route exact path="/support-request" element={AuthHook.checkIsLoggedIn === 'true' ? <ListSupportRequest /> : <Navigate to="/" replace />} />
                            <Route exact path="/add-support-request" element={AuthHook.checkIsLoggedIn === 'true' ? <AddSupportRequest /> : <Navigate to="/" replace />} />

                            {/* Contact Us */}
                            <Route exact path="/contact-us" element={<ContactUs />} />

                            {/* Search Result */}
                            <Route exact path="/search-result" element={<SearchResult />} />

                            {/* Ammount To Pay */}
                            <Route exact path="/amount-to-pay/:ammount" element={<AmmountToPay />} />

                            {/* Download Live APK */}
                            <Route exact path="/apk/promotswitches" element={<DownloadPromotSwitchesAPK />} />

                            {/* Download Dev APK */}
                            <Route exact path="/apk/dev-promotswitches" element={<DownloadDevPromotSwitchesAPK />} />

                            {/* Page Not Found */}
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                        {/* Scroll To Top */}
                        <ScrollToTop />
                        {/* Whatsapp */}
                        <Whatsapp />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <CustomerHeader />
                        <Routes>
                            {/* Customer List */}
                            <Route exact path="/customer-list" element={<CustomerList getNotifications={getNotifications}/>} />

                            {/* Download Live APK */}
                            <Route exact path="/apk/promotswitches" element={<DownloadPromotSwitchesAPK />} />

                            {/* Download Dev APK */}
                            <Route exact path="/apk/dev-promotswitches" element={<DownloadDevPromotSwitchesAPK />} />

                            {/* Page Not Found */}
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                        {/* Scroll To Top */}
                        <ScrollToTop />
                        {/* Whatsapp */}
                        <Whatsapp />
                    </React.Fragment>
                )}
                <Footer getCartDetails={getCartDetails} />
            </Suspense>
        </React.Fragment>
    );
}

export default App;
